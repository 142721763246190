<script>
  import Auditi from 'themes/Auditi.svelte'
  export let name
  
  const themes = {
    auditi: Auditi
  }
  
  console.log("Loading Theme", name)
</script>

<svelte:component this={themes[name]} />
<div class={name}>
  <slot></slot>
</div>