<div class="box">
  <h2>Login</h2>
  <input type="text" bind:value={email} />
  <input type="text" bind:value={password} />
  <input type="submit" on:click={login} />
</div>

<div class="box">
  <h2>Uploads</h2>
  <button on:click={getUploads}>Get Uploads</button>
  
  <ul>
    {#each indexData as upload (upload.id)}
      <li>
        <b><a href="{upload.url}" target="_blank">
          Upload vom {upload.created_at}
        </a></b><br />
        {#each upload.files as file (file.id)}
          {file.filename} ({file.filesize})<br />
        {/each}
      </li>
    {/each}
  </ul>
</div>

<div class="box">
  <h2>New Upload</h2>
  <input type="file" on:change={ onNewFile } multiple />
  <h3>Files</h3>
  <ul>
    {#each addedFiles as file (file.key)}
      <li>{file.name} ({file.size})</li>
    {/each}
  </ul>
  
  <h3>Options</h3>
  <label>
    Passwort:
    <input bind:value={uploadPassword} type="text" placeholder="Passwort eingeben" />
  </label>
  <label>
    Self Destruct:
    <select bind:value={selfDestruct}>
      <option>Never</option>
      <option value="1209600">After 2 Weeks</option>
    </select>
  </label>
  <label>
    Max Download Count:
    <input bind:value={maxDownloadCount} type="text" placeholder="Passwort eingeben" />
  </label>
  
  <button on:click={submit}>Submit</button>
</div>


<script>
  import oksend from 'apis/oksend-sdk.min.js'

  const api = new oksend({})
  let email = "mail@jubari.com"
  let password = "foobarfoo"
  let indexData = []
  let addedFiles = []
  
  // settings
  let uploadPassword = "test123"
  let maxDownloadCount = "1"
  let selfDestruct = "1209600"
  
  console.log("Authenticated?", api.auth.authenticated())

  function submit() {
    api.uploads.create({
      files: addedFiles,
      settings: {
        password: uploadPassword,
        maxDownloadCount: maxDownloadCount,
        selfDestruct: selfDestruct
      },
      success: () => console.log("COOL"),
      error: () => console.log("ERROR")
    })
  }
  
  function onNewFile(event, context) {
    Array.from(event.target.files).map((file) => {
      api.uploads.upload({
        file: file,
        add: onAdd,
        progress: (key, progress) => console.log(`Progress for file ${key}: ${progress}`),
        complete: (key) => console.log(`Upload Complete for file ${key}`),
        error: (key, error) => console.log(`Error while uploading file ${key}`)
      })
    })
  }
  
  function onAdd(key, name, size) {
    addedFiles = [...addedFiles, {
      key: key,
      name: name,
      size: size,
      progress: 0
    }]
  }

  function getUploads() {
    api.uploads.index({
      success: (data) => indexData = data,
      error: (error) => console.log("ERROR", error)
    })
  }

  function login() {
    api.auth.authenticate(email, password)
  }
</script>

<style>
  .box {
    border: 2px solid #eee;
    border-radius: 8px;
    padding: 20px;
    overflow: hidden;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 24px;
    margin: 20px 40px 0 40px;
  }
  
  label { display: block; margin-top: 10px; }
  
  button { margin-top: 10px; height: 30px; }
  input {
    height: 30px;
    display: inline-block;
    margin: 5px;
    padding: 0 10px;
  }
  
  ul li { margin-top: 10px; }
</style>