<Theme name="{theme}">
  <slot></slot>
</Theme>

<script>
  import { onMount } from 'svelte'
  import { initalizeI18N } from 'stores/i18n.js'
  
  import Theme from 'components/Theme.svelte'
  
  export let user
  export let language
  export let theme
  
  onMount(async () => {
    console.log("APP", $$props)
    await initalizeI18N(language)
  })
</script>