<header>
  <div class="left">
    <div class="account">{accountName}</div>
  </div>
  <div class="right">
    {#if $currentLanguage == "de"}
      <Button style="border" click={() => onChangeLanguage('en')}>EN</Button>
    {:else}
      <Button style="border" click={() => onChangeLanguage('de')}>DE</Button>
    {/if}
  </div>
</header>

<script>
  export let accountName
  
  import { currentLanguage, changeLanguage } from 'stores/i18n.js'
  
  import Button from 'components/Button.svelte'
  
  function onChangeLanguage(newLanguage) {
    changeLanguage(newLanguage)
  }
</script>

<style lang="scss">
  header {
    display: flex;
    align-items: center;
    height: 80px;
    background: #fff;
    padding: 0 var(--page-gutter);
    box-shadow: 0 3px 8px 0 rgba(41, 49, 61, 0.06);
    border-bottom: 1px solid var(--border-color);
  }
  
  .left {
    flex: 1;
  }
  
  .right {
    display: flex;
  }

  .account {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
</style>