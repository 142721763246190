<Box style="padding"
     title={$t('access_files.file_count', { count: files.length })}>
     
  <div slot="header-actions">
    {#if downloadable()}
      <Button click={onDownloadAll} style="primary">{$t('access_files.download_all')}</Button>
    {:else}
      <Button style="disabled">{$t('access_files.download_limit_reached')}</Button>
    {/if}
  </div>
  
  {#each files as file (file.permalink)}
    <FileItem {...file} />
  {/each}
  
</Box>

<script>
  export let files
  export let downloadAll
  export let remainingDownloads
  
  let currentDownloads = 0
  
  import { t, translations } from 'stores/i18n.js'
  import Box from 'components/Box.svelte'
  import FileItem from 'components/access/FileItem.svelte'
  import Button from 'components/Button.svelte'
  
  $: downloadable = function() {
    if(remainingDownloads !== undefined) {
      if(remainingDownloads - currentDownloads >= 1) { return true }
      else if(remainingDownloads == null) { return true }
      else { return false }
    } else {
      return true
    }
  }
  
  function onDownloadAll() {
    currentDownloads += 1
    window.open(downloadAll)
  }
  
  translations("en", "access_files", {
    "download_all": "Download all files",
    "file_count": "{{count}} files",
    "download_limit_reached": "Download limit reached"
  })
  
  translations("de", "access_files", {
    "download_all": "Herunterladen",
    "file_count": "{{count}} Dateien zum Download",
    "download_limit_reached": "Download-Limit erreicht"
  })
</script>