<App {...appConfig}>
  <Topbar accountName={initialState.platform.accountName} />
  <ContentSidebar>
    <div slot="content">
      {#if initialState.meta.authenticated}
        <Files
          remainingDownloads={initialState.remainingDownloads}
          downloadAll={initialState.downloadAll}
          files={initialState.files} />
      {:else}
        <Authentication />
      {/if}
    </div>
    
    <div slot="sidebar">
      <Sidebar {...initialState} />
    </div>
  </ContentSidebar>
</App>

<script>
  export let appConfig // required for App
  export let initialState // props passed from svelte_app
  
  import App from 'components/App.svelte'
  import Topbar from 'components/Topbar.svelte'
  import ContentSidebar from 'components/ContentSidebar.svelte'
  
  import Files from 'components/access/Files.svelte'
  import Authentication from 'components/access/Authentication.svelte'
  import Sidebar from 'components/access/Sidebar.svelte'
  
  console.log("Access loaded:", initialState)
</script>