import { writable, derived, get } from 'svelte/store'
import i18next from 'i18next'
import translationsEN from 'translations/en.json'
import translationsDE from 'translations/de.json'


// STORES
const t = writable(function(key) {})

const translationDB = writable({
  'en': { 'translation': translationsEN },
  'de': { 'translation': translationsDE }
})

const currentLanguage = derived(t, ($t, set) => {
  set(i18next.language)
})

const changeLanguage = (newLanguage) => {
  i18next.changeLanguage(newLanguage, (err, newT) => {
    if (err) return console.log('something went wrong loading', err)
    t.set(newT)
    window.localStorage.setItem('language', newLanguage)
  })
}

// HELPERS
const initalizeI18N = async function(initialLanguage) {
  console.log("Initializing i18n with", get(translationDB))
  
  const savedLanguage = window.localStorage.getItem('language')
  
  return i18next.init({
    lng: savedLanguage || initialLanguage,
    debug: false,
    resources: get(translationDB)
  }).then(function(_t) {
    t.set(_t)
  })
}

const translations = function(lng, toplevel, resources) {
  let newTranslations = get(translationDB)
  newTranslations[lng]['translation'][toplevel] = resources
  translationDB.update(trans => newTranslations)
}

export {
  initalizeI18N,
  t,
  currentLanguage,
  changeLanguage,
  translations
}