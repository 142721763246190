<Box>
  <div class="authenticate">
    <div class="title">{$t('access_authentication.title')}</div>
    <Form method="POST" action="" ajax="false">
      <input type="password" name="password" autofocus="true" />
      <input type="submit" />
    </Form>
  </div>
</Box>

<script>
  import { t, translations } from 'stores/i18n.js'
  import Box from 'components/Box.svelte'
  import Form from 'components/Form.svelte'
  
  translations("en", "access_authentication", {
    "title": "Please enter the password"
  })
  
  translations("de", "access_authentication", {
    "title": "Bitte geben Sie das Passwort ein"
  })
</script>

<style lang="scss">
  
  .authenticate {
    background: var(--blue-005);
    display: flex;
    height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
  }
  
  .title {
    color: var(--primary-060);
    font-size: 16px;
    margin-bottom: 20px;
  }
  
</style>