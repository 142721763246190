<Box style="padding" title={$t('access_sidebar.sender')}>
  <div class="item">
    <span class="label">{$t('access_sidebar.created_by')}</span>
    <a href="mailto:{meta.senderEmail}" class="value">{meta.senderEmail}</a>
  </div>
    
  <div class="item">
    <span class="label">{$t('access_sidebar.destroy_in')}</span>
    <span class="value">{destroyInFormatted}</span>
  </div>
  
  <div class="item">
    <span class="label">{$t('access_sidebar.downloadable_count')}</span>
    {#if meta.maxDownloadCount == 1}
      <span class="value">{$t('access_sidebar.downloadable_once')}</span>
    {:else}
      <span class="value">{$t('access_sidebar.downloadable_unlimited')}</span>
    {/if}
  </div>
  
</Box>

<div class="legals">
  <div class="legals-headline">{$t('access_sidebar.service')}</div>
  <div class="platform-name">{platform.name}</div>
  <a href="{platform.privacyLink}" class="meta-link">{$t('access_sidebar.privacy')}</a>
  <a href="{platform.imprintLink}" class="meta-link">{$t('access_sidebar.imprint')}</a>
</div>

<script>
  export let meta
  export let platform
  
  // let senderEmail
  // let senderName
  // let destroyAt
  // let imprintLink
  // let privacyLink
  
  import { t, translations, currentLanguage } from 'stores/i18n.js'
  import { distanceOfTime } from 'libs/time.js'
  import Box from 'components/Box.svelte'
  
  $: destroyInFormatted = distanceOfTime(meta.destroyAt, $currentLanguage || 'en')
  
  translations("en", "access_sidebar", {
    "sender": "Sender",
    "service": "This service is provided by",
    "created_by": "Files uploaded by",
    "destroy_in": "Will be deleted in",
    "downloadable_count": "Limits",
    "downloadable_unlimited": "No download limitations",
    "downloadable_once": "Only 1 download permitted",
    "imprint": "Imprint",
    "privacy": "Privacy"
  })
  
  translations("de", "access_sidebar", {
    "sender": "Absender",
    "service": "Dieser Service wird bereitgestellt von",
    "created_by": "Hochgeladen von",
    "destroy_in": "Wird automatisch gelöscht",
    "downloadable_count": "Limitierung",
    "downloadable_unlimited": "Unbegrenzte Anzahl von Downloads",
    "downloadable_once": "Nur 1 Download erlaubt",
    "imprint": "Impressum",
    "privacy": "Datenschutz"
  })
</script>

<style lang="scss">
  .item {
    margin-bottom: 15px;
  }
  
  .label {
    display: block;
    color: var(--primary-060);
  }
  
  .value {
    display: block;
    font-weight: 500;
  }
  
  a.value {
    color: var(--blue-text);
  }
  
  .legals {
    padding: 15px 20px;
  }
  
  .legals-headline {
    font-size: 14px;
    color: var(--primary-060);
  }
  
  .platform-name {
    font-weight: 600;
    font-size: 14px;
    color: var(--primary-060);
  }
  
  .meta-link {
    color: var(--primary-040);
    display: inline-block;
    margin-right: 10px;
    text-decoration: none;
    font-size: 12px;
    line-height: 20px;
  }
</style>