<form class="form" bind:this={form} action={action} method={method} on:submit|preventDefault={onSubmit}>
  <input bind:value={authenticityToken} type="hidden" name="authenticity_token" />
  <slot></slot>
</form>

<script>
  export let action
  export let method
  export let remote
  
  let form
  
  $: authenticityToken = document.querySelector("[name='csrf-token']").content
  
  function onSubmit(event) {
    if(remote) {
      console.log("Submitting remote")
      fetch("/v1/articles", {
        method: method,
        headers: {
          "X-CSRF-Token": authenticityToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ title: "awesome" })
      }).then(response => {
        if (!response.ok) { throw response; }
        return response.json()
      }).then((data) => {
        console.log(data)
      }).catch(error => {
        console.error("error", error)
      })
    } else {
      console.log("Submit regular", authenticityToken)
      form.submit()
    }
  }
  
</script>

<style global lang="scss">
  
  :global(input) {
    box-sizing: border-box;
    background: #fff;
    height: 40px;
    border: 1px solid var(--border-color);
    padding: 0 12px;
    border-radius: var(--border-radius);
    font-family: var(--default-font);
    font-size: 14px;
  }
  
  :global(input:focus) {
    outline: none;
    border: 1px solid var(--blue);
  }
  
</style>