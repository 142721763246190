<div class="file-item">
  <div class="icon">
    <img src={require('images/file-icons/'+ iconName() +'.svg')} alt="" />
  </div>
  <div class="center">
    <div class="filename">{filename}</div>
    <div class="filesize">({humanFileSize(filesize)})</div>
  </div>
  {#if false}
    <div class="actions">
      <Button click={onDownload} style="primary">{$t('access_file_item.download')}</Button>
    </div>
  {/if}
</div>

<script>
  export let filename
  export let filesize
  export let download
  export let downloadCount // not yet used in UI
  
  import { t, translations } from 'stores/i18n.js'
  import Button from 'components/Button.svelte'
  
  function iconName() {
    let re  = /(?:\.([^.]+))?$/
    var ext = re.exec(filename)[1]
    
    switch(ext) {
      case 'pdf':
        return 'pdf'
        break;
      case 'xlsx':
        return 'table'
        break;
      default:
        return 'file'
    }
  }
  
  function onDownload() {
    console.log("Downloading", download)
    window.open(download)
  }
  
  function humanFileSize(size) {
    let i = Math.floor( Math.log(size) / Math.log(1024) )
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }
  
  translations("en", "access_file_item", {
    "download": "Download"
  })
  
  translations("de", "access_file_item", {
    "download": "Herunterladen"
  })
</script>

<style scoped lang="scss">
  .file-item {
    padding: 10px;
    display: flex;
    flex-direction: row;
  }
  
  .center {
    flex: 1;
  }
  
  .icon {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .icon img {
    width: 30px;
  }
  
  .filename {
    font-weight: 500;
    color: var(--primary-100);
  }
  
  .filesize {
    color: var(--primary-060);
  }
</style>