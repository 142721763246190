export const distanceOfTime = (timestamp, locale) => {
  const translations = {
    'en': {
      prefix:  '',
      before:  '',
      ago:     'ago',
      in:      'in',
      seconds: 'less than a minute',
      minute:  'about a minute',
      minutes: '%d minutes',
      hour:    'about an hour',
      hours:   'about %d hours',
      day:     'a day',
      days:    '%d days',
      month:   'about a month',
      months:  '%d months',
      year:    'about a year',
      years:   '%d years'
    },
    'de': {
      prefix:  '',
      before:  'vor',
      ago:     '',
      in:      'in',
      seconds: 'einer Minute',
      minute:  'einer Minute',
      minutes: '%d Minuten',
      hour:    'einer Stunde',
      hours:   '%d Stunden',
      day:     'einem Tag',
      days:    '%d Tagen',
      month:   'einem Monat',
      months:  '%d Monaten',
      year:    'einem Jahr',
      years:   '%d Jahren'
    }
  }
  console.log(`distanceOfTime with ${locale} at ${timestamp}`)
  const locales = translations[locale]

  let seconds = Math.floor((new Date() - parseInt(timestamp)) / 1000),
      separator = locales.separator || ' ',
      words = locales.prefix + separator,
      interval = 0,
      intervals = {
        year:   Math.abs(seconds) / 31536000,
        month:  Math.abs(seconds) / 2592000,
        day:    Math.abs(seconds) / 86400,
        hour:   Math.abs(seconds) / 3600,
        minute: Math.abs(seconds) / 60
      }

  let distance = locales.seconds

  for (var key in intervals) {
    interval = Math.floor(intervals[key])

    if (interval > 1) {
      distance = locales[key + 's']
      break
    } else if (interval === 1) {
      distance = locales[key]
      break
    }
  }

  distance = distance.replace(/%d/i, interval)
  if (seconds >= 0) {
    words += locales.before + separator + distance + separator + locales.ago
  } else {
    words += locales.in + separator + distance
  }

  return words.trim()
}