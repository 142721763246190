<div class="box {style}">
  {#if title || $$slots['header-actions'] }
    <div class="header">
      {#if title}
        <div class="title">{title}</div>
      {/if}
      <div class="header-actions">
        <slot name="header-actions"></slot>
      </div>
    </div>
  {/if}
  <div class="content">
    <slot></slot>
  </div>
</div>

<script>
  export let style = ""
  export let title
</script>

<style scoped lang="scss">
  .box {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background: #fff;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding: 10px 20px;
    line-height: 40px;
  }
  
  .content {}
  
  .title {
    font-size: 16px;
    font-weight: 600;
  }
  
  .padding .content {
    padding: 20px;
  }
  
  .margin-top {
    margin-top: 20px;
  }
  
  .no-border {
    border: 0;
    border-radius: 0;
    background: transparent;
  }
</style>