<button class="{calculatedStyles()}" on:click={clickHandler}>
  
  {#if icon !== null}
    <img src="/assets/svg_icons/{icon}" alt={icon} />
  {/if}
  
  <slot></slot>
</button>

<script>  
  export let style = ''
  export let navigate = false
  export let click = false
  export let active = false
  export let icon = null
  
  $: calculatedStyles = function() {
    let s = style.split(" ")
    if(active) { s.push('active') }
    if(icon !== null) { s.push('with-icon') }
    return s.join(" ")
  }
  
  function clickHandler(event) {
    if(navigate) {
      console.log("navigating")
      // navigateTo(navigate)
    }
    
    if(click) {
      click()
    }
  }
</script>

<style global lang="scss">
  button {
    all: unset;
    position: relative;
    box-sizing: border-box;
    font-family: var(--default-font);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    padding: 0 12px;
    background: transparent;
    color: var(--blue-text);
    font-weight: 500;
    border-radius: var(--border-radius);
  }
  
  .with-icon img { margin-right: 10px; }
  
  .border-icon img {
    margin-right: 15px;
    width: 30px;
    height: 30px;
    padding: 5px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
  }

  .medium {
    height: 30px;
  }
  
  .close {
    height: 30px;
    width: 30px;
    padding: 0;
    color: var(--signal-red-text);
    border: 1px solid var(--border-color);
  }
  
  .close:hover { border-color: var(--blue); }
  
  .border {
    border: 1px solid var(--border-color);
    background: #fff;
  }
  
  .border:hover { border-color: var(--blue); }

  .primary {
    background: var(--blue);
    color: #fff;
    font-weight: 500;
  }
  
  .disabled {
    background: var(--primary-004);
    color: var(--primary-060);
    font-weight: 500;
    cursor: default;
  }
  
  .option {
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    border-top: 1px solid var(--border-color);
    height: auto;
    padding: 10px 12px;
  }
  
  .option:hover { background: var(--blue-005); }
  
  .option:first-child {
    border-top: 0;
    border-top-left-radius: var(--border-radius); 
    border-top-right-radius: var(--border-radius); 
  }
  
  .option:last-child {
    border-bottom-left-radius: var(--border-radius); 
    border-bottom-right-radius: var(--border-radius); 
  }
</style>